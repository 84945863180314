import { Grid, Row, Col, Alert } from '@freecodecamp/react-bootstrap';
import type { TFunction } from 'i18next';
import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { createSelector } from 'reselect';

import DonateForm from '../components/Donation/donate-form';
import {
  DonationText,
  DonationOptionsAlertText,
  DonationFaqText
} from '../components/Donation/donation-text-components';

import { apiLocation } from '../../../config/env.json';

import { Spacer, Loader } from '../components/helpers';
import CampersImage from '../components/landing/components/campers-image';
import { signInLoadingSelector, userSelector, executeGA } from '../redux';
import Payment from '../components/landing/components/payment';
import Header from '../components/landing/components/header';
import Footer from '../components/landing/components/footer';

export interface ExecuteGaArg {
  type: string;
  data: {
    category: string;
    action: string;
    nonInteraction?: boolean;
    label?: string;
    value?: number;
  };
}
interface PricingPageProps {
  executeGA: (arg: ExecuteGaArg) => void;
  user?: any;
  showLoading: boolean;
  t: TFunction;
}

const mapStateToProps = createSelector(
  userSelector,
  signInLoadingSelector,
  ( user, showLoading: boolean) => ({
    user,
    showLoading
  })
);

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ executeGA }, dispatch);

function PricingPage({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  executeGA = () => {},
  user,
  showLoading,
  t
}: PricingPageProps) {
  const { isDonating } = user;
  useEffect(() => {
    executeGA({
      type: 'event',
      data: {
        category: 'Donation View',
        action: `Displayed donate page`,
        nonInteraction: true
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleProcessing(duration: string, amount: number, action: string) {
    executeGA({
      type: 'event',
      data: {
        category: 'Donation',
        action: `donate page ${action}`,
        label: duration,
        value: amount
      }
    });
  }

  const quarterly = () => {
    if(user.id && user.isDonating) {
      alert("You are already subscribed. To change your subscription kindly contact support.")
    } else if (user.id && !user.isDonating) {
      location.href = apiLocation + "/donate/stripe-session?package=quarterly";
    } else {
      location.href = apiLocation + '/signin';
    }
  }
  const monthly = () => {
    if(user.id && user.isDonating) {
      alert("You are already subscribed. To change your subscription kindly contact support.")
    } else if (user.id && !user.isDonating) {
      location.href = apiLocation + "/donate/stripe-session?package=monthly";
    } else {
      location.href = apiLocation + '/signin';
    }
  }
  const annually = () => {
    if(user.id && user.isDonating) {
      alert("You are already subscribed. To change your subscription kindly contact support.")
    } else if (user.id && !user.isDonating) {
      location.href = apiLocation + "/donate/stripe-session?package=annually";
    } else {
      location.href = apiLocation + '/signin';
    }
  }

  return <>
    <Helmet>
      <title>Extraise</title>
      <link rel="shortcut icon" href="/assets/images/logo/favourite_icon_1.png" />
      <link rel="stylesheet" type="text/css" href="/assets/css/bootstrap.min.css" />
      <link rel="stylesheet" type="text/css" href="/assets/css/icomoon.css" />
      <link rel="stylesheet" type="text/css" href="/assets/css/themify-icons.css" />
      <link rel="stylesheet" type="text/css" href="/assets/css/fontawesome-all.css" />
      <link rel="stylesheet" type="text/css" href="/assets/css/owl.carousel.min.css" />
      <link rel="stylesheet" type="text/css" href="/assets/css/owl.theme.default.min.css" />
      <link rel="stylesheet" type="text/css" href="/assets/css/aos.css" />
      <link rel="stylesheet" type="text/css" href="/assets/css/animate.css" />
      <link rel="stylesheet" type="text/css" href="/assets/css/splitting.css" />
      <link rel="stylesheet" type="text/css" href="/assets/css/magnific-popup.css" />
      <link rel="stylesheet" type="text/css" href="/assets/css/style.css" />
      <link rel="stylesheet" type="text/css" href="/assets/css/_extraise.css" />
      <script defer key="jquery" src="/assets/js/jquery-3.4.1.min.js" />
      <script defer key="popper" src="/assets/js/popper.min.js" />
      <script defer key="bootstrap" src="/assets/js/bootstrap.min.js" />
      <script defer key="owl-carousel" src="/assets/js/owl.carousel.min.js" />
      <script defer key="aos" src="/assets/js/aos.js" />
      <script defer key="splitting" src="/assets/js/splitting.js" />
      <script defer key="magnific-popup" src="/assets/js/magnific-popup.min.js" />
      <script defer key="isotope" src="/assets/js/isotope.pkgd.min.js" />
      <script defer key="masonry" src="/assets/js/masonry.pkgd.min.js" />
      <script defer key="imagesloaded" src="/assets/js/imagesloaded.pkgd.min.js" />
      <script defer key="parallax" src="/assets/js/parallax.min.js" />
      <script defer key="parallax-scroll" src="/assets/js/parallax-scroll.js" />
      <script defer key="gmaps" src="/assets/js/gmaps.js" />
      <script defer key="mCustomScrollbar" src="/assets/js/mCustomScrollbar.js" />
      <script defer key="custom" src="/assets/js/custom.js" />
    </Helmet>

    <main>
      <Header />
      <section id="breadcrumb_section" className="breadcrumb_section bg_gradient_blue deco_wrap d-flex align-items-center text-white clearfix" style={{ minHeight: "auto", padding: "55px 0" }}>
        {/* <div className="container">
          <div className="breadcrumb_content text-center" data-aos-delay={100}>
            <h1 className="page_title" style={{ fontSize: "25px" }}>Pricing Plans</h1>
            <p className="mb-0">
              Stay ahead of the curve with
              Extraise Pro.
            </p>
          </div>
        </div>
        <div className="deco_image spahe_1" data-aos="fade-down" data-aos-delay={300}>
          <img src="/assets/images/shapes/shape_1.png" alt="spahe_not_found" />
        </div>
        <div className="deco_image spahe_2" data-aos-delay={400}>
          <img src="/assets/images/shapes/shape_2.png" alt="spahe_not_found" />
        </div> */}
      </section>
      <section className="pricing_section sec_ptb_60 bg_gray_2 clearfix">
        <div className="container">
          <div className="row justify-content-center mb-50">
            <div className="col-lg-5">
              <div className="section_title text-center">
                <h2 className="title_text mb-30">Stay ahead of the curve with Extraise Ultimate.</h2>
                <p className="mb-0">
                75% of developers learn new tech skills at least once a year. Stand out from the crowd with real-world skills and accelerate your career.
                </p>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            {/* <div className="col-lg-3 col-md-3" data-aos-delay={300}>
              <div className="pricing_plan_2">
                <div className="pricing_header text-center">
                  <h3 className="title_text text_lightblue mb-30">Free</h3>
                  <div className="price_text">
                    <sup>₹</sup>
                    <strong>0</strong>
                    <sub>/mo</sub>
                  </div>
                </div>
                <div className="info_list ul_li_block">
                  <ul className="clearfix">
                    <li>20 Free Challenges <i className="fas fa-check-circle" /></li>
                  </ul>
                </div>
                <a href="#!" className="btn bg_default_blue">Get Now</a>
              </div>
            </div> */}
            <div className="col-lg-3 col-md-3" data-aos-delay={500}>
              <div className="pricing_plan_2">
                <div className="item_label">
                  {/* <img src="assets/images/label_1.png" alt="image_not_found" /> */}
                </div>
                <div className="pricing_header text-center">
                  <h3 className="title_text text_lightgreen mb-30">Beginner</h3>
                  <div className="price_text">
                    <sup>$</sup>
                    <strong>12</strong>
                    <sub>/mo</sub>
                  </div>
                  <small>$ 149 Billed Anually</small>
                </div>
                <div className="info_list ul_li_block">
                  <ul className="clearfix">
                    <li>Full Access <i className="fas fa-check-circle" /></li>
                    <li>Certification <i className="fas fa-check-circle" /></li>
                    <li>Assessments <i className="fas fa-check-circle" /></li>
                    <li>Projects <i className="fas fa-check-circle" /></li>
                    <li>Interview Prep <i className="fas fa-check-circle" /></li>
                    <li>Peer Support <i className="fas fa-times-circle" /></li>
                    <li>Live MasterClasses <i className="fas fa-times-circle" /></li>
                  </ul>
                </div>
                <a href='javascript:void(0)' onClick={ annually } className="btn bg_default_blue">Try Now</a>
              </div>
            </div>
            <div className="col-lg-3 col-md-3" data-aos-delay={700}>
              <div className="pricing_plan_2">
                <div className="pricing_header text-center">
                  <h3 className="title_text text_orange mb-30">Intermediate</h3>
                  <div className="price_text">
                    <sup>$</sup>
                    <strong>23</strong>
                    <sub>/mo</sub>
                  </div>
                  <small>$ 69 Billed Quarterly</small>
                </div>
                <div className="info_list ul_li_block">
                  <ul className="clearfix">
                    <li>Full Access <i className="fas fa-check-circle" /></li>
                    <li>Certification <i className="fas fa-check-circle" /></li>
                    <li>Assessments <i className="fas fa-check-circle" /></li>
                    <li>Projects <i className="fas fa-check-circle" /></li>
                    <li>Interview Prep <i className="fas fa-check-circle" /></li>
                    <li>Peer Support <i className="fas fa-times-circle" /></li>
                    <li>Live MasterClasses <i className="fas fa-times-circle" /></li>
                  </ul>
                </div>
                <a href='javascript:void(0)' onClick={ quarterly } className="btn bg_default_blue">Try Now</a>
              </div>
            </div>
            <div className="col-lg-3 col-md-3" data-aos-delay={700}>
              <div className="pricing_plan_2">
                <div className="pricing_header text-center">
                  <h3 className="title_text text_orange mb-30">Advanced</h3>
                  <div className="price_text">
                    <sup>$</sup>
                    <strong>69</strong>
                    <sub>/mo</sub>
                  </div>
                  <small>$ 69 Billed Monthly</small>
                </div>
                <div className="info_list ul_li_block">
                  <ul className="clearfix">
                    <li>Full Access <i className="fas fa-check-circle" /></li>
                    <li>Certification <i className="fas fa-check-circle" /></li>
                    <li>Assessments <i className="fas fa-check-circle" /></li>
                    <li>Real World Projects <i className="fas fa-check-circle" /></li>
                    <li>Interview Prep <i className="fas fa-check-circle" /></li>
                    <li>Peer Support <i className="fas fa-check-circle" /></li>
                    <li>Live MasterClasses <i className="fas fa-check-circle" /></li>
                  </ul>
                </div>
                <a href='javascript:void(0)' onClick={ monthly } className="btn bg_default_blue">Try Now</a>
              </div>
            </div>
            <div className="col-lg-3 col-md-3" data-aos-delay={700}>
              <div className="pricing_plan_2">
                <div className="pricing_header text-center">
                  <h3 className="title_text text_orange mb-30">Team / School</h3>
                  <div className="price_text">
                    <sup>$</sup>
                    <strong>129</strong>
                    <sub></sub>
                  </div>
                  <small>Per Student (3 Months)</small>
                </div>
                <div className="info_list ul_li_block">
                  <ul className="clearfix">
                    <li>Full Access <i className="fas fa-check-circle" /></li>
                    <li>Certification <i className="fas fa-check-circle" /></li>
                    <li>Assessments <i className="fas fa-check-circle" /></li>
                    <li>Real World Projects <i className="fas fa-check-circle" /></li>
                    <li>Interview Prep <i className="fas fa-check-circle" /></li>
                    <li>Peer Support <i className="fas fa-check-circle" /></li>
                    <li>Live MasterClasses <i className="fas fa-check-circle" /></li>
                  </ul>
                </div>
                <a href="mailto:contact@extraise.com" className="btn bg_default_blue">Talk to Us</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </main>
  </>
}

PricingPage.displayName = 'PricingPage';

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(PricingPage));
